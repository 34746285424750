import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Slider from "react-slick";
import {
  FaGem,
  FaAward,
  FaExchangeAlt,
  FaShippingFast,
  FaWrench,
  FaShieldAlt,
} from "react-icons/fa";

const featuresData = [
  {
    icon: <FaGem size={40} />,
    title: "Elegant Designs",
  },
  {
    icon: <FaAward size={40} />,
    title: "Verified Jewellery",
  },
  {
    icon: <FaExchangeAlt size={40} />,
    title: "Easy Exchange",
  },
  {
    icon: <FaShippingFast size={40} />,
    title: "Fast Delivery",
  },
  {
    icon: <FaShieldAlt size={40} />,
    title: "BIS Hallmarked",
  },
  {
    icon: <FaWrench size={40} />,
    title: "Customization Options",
  },
];

const FeaturesSection = () => {
  const settings = {
    dots: true,
    infinite: true,
    arrows: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
  };

  return (
    <div className="FeaturesSectionRow">
      <Container className="text-center">
        <div className="d-md-none">
          <Slider {...settings}>
            {featuresData.map((feature, index) => (
              <div key={index} className="feature-card py-5 border-0">
                {feature.icon}
                <h5 className="featuretitle text-muted mt-4 m-0">
                  {feature.title}
                </h5>
              </div>
            ))}
          </Slider>
        </div>

        <Row className="d-none d-md-flex">
          {featuresData.map((feature, index) => (
            <Col key={index} xs={4} md={2} lg={2}>
              <div className="feature-card py-lg-5 py-sm-4 py-4 border-0">
                {feature.icon}
                <h5 className="featuretitle text-muted mt-4 m-0">
                  {feature.title}
                </h5>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
};

export default FeaturesSection;
