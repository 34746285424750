import React from "react";
import { Card, Row, Col } from "react-bootstrap";
import stringItImage from "../Images/AEKTA__458_x_458.jpg";
import italianConnectionImage from "../Images/ASTRA__458_x_458.jpg";
import engagementRingsImage from "../Images/CROWN_STAR__458_x_458.jpg";

const JewelryCards = () => {
  return (
    <div className="container py-5">
      <Row className="justify-content-center">
        <Col className="px-lg-3 px-sm-2 px-1" xs={4} sm={4} md={4} lg={4}>
          <Card className="jewelry-card">
            <Card.Img variant="top" src={stringItImage} />
          </Card>
        </Col>

        <Col className="px-lg-3 px-sm-2 px-1" xs={4} sm={4} md={4} lg={4}>
          <Card className="jewelry-card">
            <Card.Img variant="top" src={italianConnectionImage} />
          </Card>
        </Col>

        <Col className="px-lg-3 px-sm-2 px-1" xs={4} sm={4} md={4} lg={4}>
          <Card className="jewelry-card">
            <Card.Img variant="top" src={engagementRingsImage} />
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default JewelryCards;
