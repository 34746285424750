import React from "react";
import { Container, Row } from "react-bootstrap";

const Footer = () => {
  return (
    <footer className="text-center bg-light">
      <Container>
        <Row>
          <div className="footerend text-center py-2">
            <p className="mb-0">
              Copyright © 2024 all rights reserved by
              <a
                className="ms-1 text-decoration-none"
                href="https://liontouchwebpark.com/"
                aria-label="Liontouch Webpark"
              >
                Liontouch Webpark PVT.
              </a>
            </p>
          </div>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
