import React from "react";
import "./App.css";
import RoutesComponents from "./RoutesComponents";
import "bootstrap/dist/css/bootstrap.min.css";

function App() {
  return (
    <div>
      <RoutesComponents />
    </div>
  );
}
export default App;
