import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Slider from "react-slick";
import Video1 from "../Images/product-vid1.mp4";
import Video2 from "../Images/product-vid2.mp4";
import Video3 from "../Images/product-vid9.mp4";
import Video4 from "../Images/product-vid4.mp4";

const JewelryVidSec = () => {
  const videos = [Video1, Video2, Video3, Video4];

  const settings = {
    dots: true,
    infinite: true,
    arrows: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Container className="my-5">
      <h2 className="text-center mb-4 JewelryVidSecTxt1">
        Discover Our Jewelry Collection
      </h2>

      {/* Slider for small screens */}
      <div className="d-md-none">
        <Slider {...settings}>
          {videos.map((video, index) => (
            <div key={index} className="video-container px-2">
              <video
                className="video w-100"
                controls
                autoPlay
                muted
                loop
                playsInline
                preload="none"
              >
                <source src={video} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          ))}
        </Slider>
      </div>

      {/* larger screens */}
      <Row className="d-none d-md-flex">
        {videos.map((video, index) => (
          <Col
            key={index}
            md={3}
            className="d-flex align-items-center justify-content-center mb-4"
          >
            <div className="video-container">
              <video
                className="video w-100"
                controls
                autoPlay
                muted
                loop
                playsInline
                preload="none"
              >
                <source src={video} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default JewelryVidSec;
