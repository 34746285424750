import React from "react";
import { Container, Row } from "react-bootstrap";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import sliderImage1 from "../Images/home_slider_HOME_PAGE_-1.jpg";
import sliderImage2 from "../Images/home_slider_HOME_PAGE_-21.jpg";
import sliderImage3 from "../Images/home_slider_HOME_PAGE_-3.jpg";

const largeScreenSliderSettings = {
  dots: false,
  arrows: false,
  fade: false,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  speed: 500,
  autoplaySpeed: 3000,
  cssEase: "linear",
  waitForAnimate: false,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};

const TopSlider = () => {
  return (
    <Container fluid>
      <Row className="p-0">
        <div className="ps-0 pe-0">
          <Slider {...largeScreenSliderSettings}>
            <div className="d-flex justify-content-center">
              <img
                src={sliderImage1}
                alt="categoryimage"
                className="img-fluid img-cover"
              />
            </div>
            <div className="d-flex justify-content-center">
              <img
                src={sliderImage2}
                alt="categoryimage"
                className="img-fluid img-cover"
              />
            </div>
            <div className="d-flex justify-content-center">
              <img
                src={sliderImage3}
                alt="categoryimage"
                className="img-fluid img-cover"
              />
            </div>
          </Slider>
        </div>
      </Row>
    </Container>
  );
};

export default TopSlider;
