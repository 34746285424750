import React, { useState, useEffect } from "react";
import { Image } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { useLocation } from "react-router-dom";
import logo from "../Images/dharm_gold-removebg-preview.png";
import "bootstrap-icons/font/bootstrap-icons.css";

function Header() {
  const [isOpen, setIsOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const location = useLocation();

  const handleToggle = () => {
    setIsOpen((prevState) => !prevState);
  };

  const handleScroll = () => {
    if (window.scrollY > 50) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };

  const isActiveLink = (path) => location.pathname === path;

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <Navbar
      expand="lg"
      className={`headermain p-0  ${
        isScrolled ? "gradient-bg scrolled" : "bg-transparent"
      }`}
    >
      <Container>
        <Navbar.Brand href="" className="d-flex align-items-center p-0">
          <Image src={logo} className="headerLogo" alt="Logo" />
        </Navbar.Brand>
        <Navbar.Toggle
          className="p-0"
          aria-controls="navbarScroll"
          onClick={handleToggle}
        >
          <i className={`bi ${isOpen ? "bi-x" : "bi-list"} NavbarToggle`}></i>
        </Navbar.Toggle>
        <Navbar.Collapse
          id="navbarScroll"
          className="justify-content-end"
          in={isOpen}
        >
          <Nav className="my-lg-0">
            <Nav.Link
              className={`nav-item ms-2 me-2 ${
                isActiveLink("/") ? "active" : ""
              }`}
              href="/"
            >
              Home
            </Nav.Link>

            <Nav.Link
              className={`nav-item ms-2 me-2 ${
                isActiveLink("/Shop") ? "active" : ""
              }`}
              href="/"
            >
              Shop
            </Nav.Link>

            <Nav.Link
              className={`nav-item ms-2 me-2 ${
                isActiveLink("/About") ? "active" : ""
              }`}
              href="/"
            >
              About
            </Nav.Link>

            <Nav.Link
              className={`nav-item ms-2 me-2 ${
                isActiveLink("/ContactUS") ? "active" : ""
              }`}
              href="/ContactUS"
            >
              Contact Us
            </Nav.Link>
          </Nav>

          <div className="social_container ms-3 me-3 d-flex align-items-center justify-content-center">
            <a
              href="#"
              target="_blank"
              rel="noreferrer"
              className="social-icon"
            >
              <i className="header_social_icn bi bi-facebook"></i>
            </a>

            <a
              target="_blank"
              rel="noreferrer"
              href="https://wa.me/919726099555"
              className="social-icon"
            >
              <i className="header_social_icn bi bi-whatsapp"></i>
            </a>

            <a target="_blank" rel="noreferrer" href="" className="social-icon">
              <i className="header_social_icn bi bi-youtube"></i>
            </a>
          </div>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;
