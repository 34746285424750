import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ringImg from "../Images/favicon-pc1ivx190hoxzdk4un0g8tacdtry621xy6fr4gnjyi.png";

function JewelryLanding() {
  return (
    <Container
      fluid
      className="text-center py-5 d-flex flex-column align-items-center justify-content-center bg-light"
    >
      <Row className="w-100">
        <Col xs={12} md={8} lg={6} className="mx-auto">
          <div className="text-section1">
            <img src={ringImg} alt="Ring Icon" className="mb-4" />
            <h2 className="display-6 text-section2 font-weight-bold">
              Crafting High-Quality, Handcrafted Jewelry for Over a Decade
            </h2>
            <p className="mt-4 m-0">
              With unwavering passion and values, we create exquisite jewelry
              that celebrates individuality and craftsmanship. Each piece is a
              testament to our dedication to artistry and quality, designed to
              enhance your unique style and commemorate life's special moments.
            </p>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default JewelryLanding;
