import React from "react";
import { Card, Container, Row } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import Slider from "react-slick";
import img1 from "../Images/product1.jpg";
import img2 from "../Images/product2.jpg";
import img3 from "../Images/product3.jpg";
import img4 from "../Images/product4.jpg";
import img5 from "../Images/product5.jpg";
import img6 from "../Images/product6.jpg";
import img7 from "../Images/product7.jpg";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const products = [
  { id: 1, imgSrc: img1 },
  { id: 2, imgSrc: img2 },
  { id: 3, imgSrc: img3 },
  { id: 4, imgSrc: img4 },
  { id: 5, imgSrc: img5 },
  { id: 6, imgSrc: img6 },
  { id: 7, imgSrc: img7 },
];

const ProductSlider = () => {
  const settings = {
    dots: false,
    arrows: true,
    fade: false,
    infinite: true,
    autoplay: true,
    speed: 500,
    autoplaySpeed: 2000,
    slidesToShow: 5,
    slidesToScroll: 1,
    initialSlide: 0,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="bg-light">
      <Container className="py-5">
        <Row className="">
          <div className="col">
            <Slider {...settings} className="px-4">
              {products.map((product) => (
                <div
                  key={product.id}
                  className="d-flex justify-content-center product-slide"
                >
                  <Card className="product-card m-lg-2 m-sm-1 m-1 border-0 rounded">
                    <div className="image-container">
                      <Card.Img
                        variant="top"
                        src={product.imgSrc}
                        className="product-image rounded-4"
                      />
                    </div>
                  </Card>
                </div>
              ))}
            </Slider>
          </div>
        </Row>
      </Container>
    </div>
  );
};

export default ProductSlider;
