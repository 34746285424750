import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Row, Col } from "react-bootstrap";

export default function Contact() {
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();

    const phoneRegex = /^[0-9]{10}$/;

    if (!phoneRegex.test(phoneNumber)) {
      setError("Please enter a valid 10-digit phone number.");
      return;
    }

    const whatsappNumber = "9726099555";
    const url = `https://wa.me/${whatsappNumber}?text=${encodeURIComponent(
      `Name: ${fullName}\nEmail: ${email}\nPhone: ${phoneNumber}\nMessage: ${message}\n~From Your Jewelry Shop`
    )}`;

    window.open(url, "_blank");
    setFullName("");
    setEmail("");
    setPhoneNumber("");
    setMessage("");
    setError("");
  };

  return (
    <Container className="contact-container py-5">
      <h1 className="text-center contactusText mb-4">Contact Us</h1>

      <Row className="align-items-center">
        <Col md={4} className="location-col">
          <div className="mb-3">
            <h4 className="location-title">Our Location :</h4>
            <p className="addreshTxt m-0">
              Plot No. 34, Shop No, 4, 1st Floor,
              <br />
              Bhagunagar, Above Saurashtra Dairy,
              <br />
              L.H. Road, Surat-395006
            </p>
          </div>
          <div className="mb-3">
            <p className="emailTxt">
              <h4 className="email-title">Email :</h4>
              <a className="contactTxt001" href="mailto:dharmgold@gmail.com">
                dharmgold@gmail.com
              </a>
            </p>
          </div>
          <div className="mb-3">
            <p className="phoneNumTxt">
              <h4 className="email-title">Phone :</h4>
              <a className="contactTxt001" href="tel:+918469481555">
                84694 81555
              </a>
            </p>
          </div>
        </Col>

        <Col md={8} className="message-col mt-lg-0 mt-4">
          <h4 className="message-title mb-4">Send Us a Message</h4>
          <form onSubmit={handleSubmit}>
            <Row className="mb-3">
              <Col>
                <input
                  type="text"
                  value={fullName}
                  onChange={(e) => setFullName(e.target.value)}
                  placeholder="Full Name"
                  className="form-control"
                  required
                />
              </Col>
            </Row>
            <Row className="mb-3">
              <Col className="pe-1">
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Email"
                  className="form-control"
                  required
                />
              </Col>
              <Col className="ps-1">
                <input
                  type="tel"
                  value={phoneNumber}
                  onChange={(e) => {
                    setPhoneNumber(e.target.value);
                    setError("");
                  }}
                  placeholder="Phone Number"
                  className="form-control"
                  required
                />
                {error && <div className="text-danger">{error}</div>}
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                <textarea
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  placeholder="Type your message here..."
                  rows="4"
                  className="form-control"
                  required
                />
              </Col>
            </Row>
            <button type="submit" className="sendmsgbutton rounded border-0">
              Send Message
            </button>
          </form>
        </Col>
      </Row>
    </Container>
  );
}
