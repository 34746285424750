import React from "react";
import Header from "./Components/Header.js";
import Footer from "./Components/Footer.js";
import FeaturesSection from "./Components/FeaturesSection.js";
import TopSlider from "./Components/TopSlider.js";
import JewelryCards from "./Components/JewelryCards.js";
import JewelryLanding from "./Components/JewelryLanding.js";
import ProductSlider from "./Components/ProductSlider.js";
import JewelryVidSec from "./Components/JewelryVidSec.js";
import Contact from "./Components/Contact.js";

const Home = () => {
  return (
    <>
      <Header />
      <TopSlider />
      <JewelryCards />
      <ProductSlider />
      <FeaturesSection />
      <JewelryVidSec />
      <JewelryLanding />
      <Contact />
      <Footer />
    </>
  );
};

export default Home;
